"use client";

import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";

// 1. Get projectId
const projectId = "e37d4209dee08af2bb7e3b81c0563972";

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl:
    "https://eth-goerli.g.alchemy.com/v2/9rg2xOLkzBaYDAM7fJS3qDy55AylhkPU",
};

// 3. Create modal
const metadata = {
  name: "팬덤다오",
  description: "My Website description",
  url: "https://fandom-swart.vercel.app",
  icons: [
    "https://maxst.icons8.com/vue-static/icon/landing/benefits/benefit2-2x.webp",
  ],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId,
});

export const Web3ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return children;
};
