"use client";

import "~/styles/globals.css";
import { RecoilRoot } from "recoil";
import { TRPCReactProvider } from "~/trpc/react";
import { usePathname } from "next/navigation";
import { RootStyleRegistry } from "~/components/Atoms/RootStyleRegistry";
import BottomNavBarModule from "~/components/Modules/BottomNavBar/BottomNavBarModule";
import { SessionProvider } from "next-auth/react";
import { Layout } from "antd";
import { hotjar } from "react-hotjar";
import { useEffect, useState } from "react";
import { Web3ModalProvider } from "~/components/Auth/Web3Modal";
import LeftSectionTemplate from "~/components/Templates/LeftSectionTemplate";
import AuthGuard from "~/components/Auth/AuthGuard";

export default function GlobalLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname();
  const hasBottomNavBar = ![
    "/login",
    "/login/callback",
    "/terms",
    "/selectCountry",
    "/setNickname",
    "/myFandomSetting",
    "/joinFandom",
    "/home",
    "/event",
    "/eventVote",
    /^\/event\/.+/,
    /^\/vote\/\d+$/,
    /^\/feed\/\d+$/,
    /^\/commentDetail\/\d+$/,
    /^\/vote\/\d+\/comment+$/,
  ].some((path) => {
    if (typeof path === "string") {
      return pathname === path;
    } else if (path instanceof RegExp) {
      return path.test(pathname);
    }
  });
  useEffect(() => {
    if (window.location.href.includes("//www")) {
      window.location.href = window.location.href.replace("//www.", "//event.");
      return;
    }

    if (window.location.href.includes("//fandomdao.com")) {
      window.location.href = window.location.href.replace(
        "//fandomdao.com",
        "//event.fandomdao.com",
      );
      return;
    }

    const useragt = window.navigator.userAgent.toLowerCase();
    const target_url = window.location.href;

    if (useragt.match(/kakaotalk/i)) {
      window.location.href =
        "kakaotalk://web/openExternal?url=" + encodeURIComponent(target_url);
      return;
    }

    hotjar.initialize(3830792, 6);
    const viewport = document.querySelector("meta[name=viewport]");
    if (viewport) {
      let content = viewport.getAttribute("content");
      if (content) {
        content = content.replace(/initial-scale=[^,]+,?/, "");
        viewport.setAttribute("content", content);
      }
    }
  }, []);

  const BottomNavBarProps: React.ComponentProps<typeof BottomNavBarModule> = {
    bottomNavBarItemProps: bottomNavBarItemProps,
    pathname,
  };

  return (
    <RootStyleRegistry>
      <SessionProvider>
        <RecoilRoot>
          <Web3ModalProvider>
            <TRPCReactProvider cookies="">
              {/* Full Section */}
              <div className="relative flex h-[100vh] flex-row justify-center border-0 bg-[#000]">
                {/* Left Section */}
                <div className="sticky top-0 hidden h-fit w-[400px] desktop:block">
                  <LeftSectionTemplate />
                </div>

                {/* Right Section */}
                <div className="flex h-full w-[400px] flex-col desktop:w-[400px]">
                  {/* Shadow */}
                  <div className="fixed z-[-1] h-full w-[400px] flex-col shadow-custom desktop:w-[400px]" />

                  {/* Content */}
                  <Layout>
                    <div
                      className={`${
                        hasBottomNavBar ? "h-[calc(100vh-67px)]" : "h-[100vh]"
                      } pt-[54px]`}
                    >
                      {/* 페이지 접근권한과 Recoil 기능 활용을 위한 컴포넌트 */}
                      <AuthGuard>{children}</AuthGuard>
                    </div>

                    {/* Bottom */}
                    {hasBottomNavBar && (
                      <BottomNavBarModule {...BottomNavBarProps} />
                    )}
                  </Layout>
                </div>
              </div>
            </TRPCReactProvider>
          </Web3ModalProvider>
        </RecoilRoot>
      </SessionProvider>
    </RootStyleRegistry>
  );
}

const bottomNavBarItemProps: React.ComponentProps<
  typeof BottomNavBarModule
>["bottomNavBarItemProps"] = [
  {
    title: "Home",
    iconProps: { iconName: "home", width: 24, height: 24 },
    href: "/home",
  },
  {
    title: "Fandom",
    iconProps: { iconName: "crown", width: 24, height: 24 },
    href: "/fandom",
  },
  {
    title: "Vote",
    iconProps: { iconName: "vote", width: 24, height: 24 },
    href: "/vote",
  },
  {
    title: "Me",
    iconProps: { iconName: "defaultAvatar", width: 24, height: 24 },
    href: "/myPage",
  },
];
