import SVGAtom from "../Atoms/SVGAtom/SVGAtom";

const LeftSectionTemplate = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-[15px] px-[25px]">
      <div className="fixed top-[50%]">
        <SVGAtom iconName="eventLogo" width={183} height={20} />
      </div>
    </div>
  );
};

export default LeftSectionTemplate;
